#wrap {
  background-color: var(--black);
}

#main {
  overflow: hidden;
  color: var(--white);
}

// line animation
.line {
  position: relative;

  @include mobile {
    &.email-ani1 {
      .left {
        display: block;
        left: 50%;
      }
    }
  }

  > span {
    position: absolute;
    background-color: var(--black300);
    transition: transform 1.5s ease-in-out;
  }

  .left {
    top: 0;
    left: -1px;
    width: 1px;
    height: 100%;
    transform-origin: 'left bottom';
    transform: scaleY(0);

    @include mobile {
      display: none;
    }
  }

  .top {
    top: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    transform-origin: 'bottom left';
    transform: scaleX(0);
  }

  .top-center {
    top: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
  }

  .bottom {
    bottom: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
  }
}

// dragg 방지
.nonDragg {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// loading
.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// error
.errorMessage_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

// clearfix
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
