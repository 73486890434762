#content-partners {
  padding-top: 178px;
  padding-bottom: 17.448vw;

  @include desktopToTablet {
    padding-top: 120px;
  }

  @include tabletToMobile {
    padding-top: 92px;
  }

  @include mobile {
    padding-top: 92px;
  }

  h2 {
    // margin-bottom: 200px;
    margin-bottom: 6.25vw;
    // font-size: 6.25rem;
    font-size: 5.208vw;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;

    @include mobile {
      font-size: 2.5rem;
      margin-bottom: 62px;
    }
  }

  .imgBox-partners_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // gap: 280px;
    gap: 14.583vw;

    @include mobile {
      gap: 30px;
    }

    .img-partners {
      width: 100%;
      display: flex;
      transform: translateY(100%);

      @include mobile {
        transform: translateY(50%);

        &:nth-child(6) {
          .logo-partners {
            img {
              width: 107px;
            }
          }
        }

        &:last-child {
          .logo-partners {
            img {
              width: 62px;
              height: 62px;
              border-radius: 50%;
            }
          }
        }
      }

      &:nth-child(odd) {
        justify-content: flex-start;

        @include mobile {
          justify-content: center;
        }

        .logo-partners {
          right: 0;
          transform: translate(30%, 50%);

          @include mobile {
            left: 50%;
            transform: translate(-50%, 0%);
          }
        }
      }

      &:nth-child(even) {
        justify-content: flex-end;

        @include mobile {
          justify-content: center;
        }

        .logo-partners {
          left: 0;
          transform: translate(-20%, 50%);

          @include mobile {
            left: 50%;
            transform: translate(-50%, 0%);
          }
        }
      }

      .bg-partners {
        position: relative;
        width: 80%;

        @include mobile {
          max-width: 331px;
          width: auto;
        }

        .bg-partners_inner {
          overflow: hidden;

          > img {
            filter: blur(300px);
            @include mobile {
              width: auto;
              height: 480px;
            }
          }
        }

        .logo-partners {
          position: absolute;
          bottom: 0;
          overflow: hidden;

          @include mobile {
            bottom: 40px;
          }

          img {
            height: 3.281vw;
            opacity: 0;

            @include mobile {
              max-width: 172px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
