#contact {
  > #youCanTrust-main {
    padding-top: 178px;

    @include desktopToTablet {
      padding-top: 120px;
    }
  
    @include tabletToMobile {
      padding-top: 92px;
    }
  
    @include mobile {
      padding-top: 92px;
    }
  }
}