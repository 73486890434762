#week-magazine {
  padding-top: 178px;
  opacity: 0;

  @include desktopToTablet {
    padding-top: 120px;
  }

  @include tabletToMobile {
    padding-top: 92px;
  }

  @include mobile {
    padding-top: 92px;
  }

  .title-magazine {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 9.375vw;

    @include mobile {
      margin-bottom: 90px;
    }

    h2 {
      // font-size: 6.25rem;
      font-size: 5.208vw;
      font-weight: 300;
      // margin-bottom: 150px;
      // letter-spacing: -3px;
      letter-spacing: -0.156vw;
      margin-bottom: 7.813vw;

      @include mobile {
        font-size: 2.5rem;
        margin: 0;
      }
    }

    h3 {
      // font-size: 7.5rem;
      font-size: 6.25vw;
      font-weight: 300;
      // letter-spacing: -3.6px;
      letter-spacing: -0.188vw;

      @include mobile {
        display: none;
      }
    }
  }

  .week-magazine_wrap {
    position: relative;
    width: 100%;

    // #smoke_video {
    //   width: 100%;
    // }

    .week-magazine_container {
      width: 100%;
      // background-image: url(../../images/magazine/magazine_smoke.gif);
      // background-repeat: no-repeat;
      // background-position: top 80px left;
      // background-size: 100% 80%;

      @include mobile {
        background-position: center;
        background-size: 100% 210px;
      }

      .swiper-wrapper {
        display: flex;
        align-items: center;
      }

      .swiper-slide {
        .post_container {
          // max-width: 522px;
          max-width: 27.188vw;

          @include mobile {
            max-width: 180px;
          }

          .img-post {
            width: 100%;
            // height: 848px;
            height: 44.167vw;

            @include mobile {
              // width: 180px;
              height: 292px;
            }
          }
        }

        &.swiper-slide-active {
          .post_container {
            max-width: none;
            .img-post {
              width: 100%;
              // height: 1050px;
              height: 54.688vw;

              @include mobile {
                // width: 200px;
                height: 325px;
              }
            }
          }
        }

        &.swiper-slide-prev,
        &.swiper-slide-next {
          display: flex;
        }

        &.swiper-slide-prev {
          justify-content: flex-start;
        }

        &.swiper-slide-next {
          justify-content: flex-end;
        }
      }

      .post_container {
        display: flex;
        flex-direction: column;
        // gap: 30px;
        gap: 1.875vw;

        @include mobile {
          gap: 12px;
        }

        .img-post {
          position: relative;
          overflow: hidden;

          img {
            // width: auto;
            height: 100%;
            object-fit: cover;

            @include mobile {
              height: 325px;
            }
          }

          a {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: rgba(65, 47, 255, 0.3);
            transition: opacity 0.3s ease-out;
            z-index: 1;
            opacity: 0;

            > div {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              // gap: 30px;
              gap: 1.563vw;

              @include mobile {
                gap: 5px;
              }

              img {
                max-width: 31px;
                width: 1.615vw;
                height: auto;

                @include mobile {
                  width: 8px;
                }
              }

              p {
                // font-size: 3.125rem;
                font-size: 2.604vw;
                line-height: 96%;
                letter-spacing: -1.5px;
                text-transform: uppercase;

                @include mobile {
                  font-size: 1.1667rem;
                }
              }
            }
          }

          &:hover {
            a {
              opacity: 1;
            }
          }
        }

        .textBox-post {
          display: flex;
          flex-direction: column;
          // gap: 30px;
          gap: 1.563vw;

          @include mobile {
            gap: 1rem;
          }

          .title-post {
            width: 100%;
            // font-size: 40px;
            font-size: 2.083vw;
            font-weight: 500;
            line-height: 120%;
            // letter-spacing: -1.2px;
            letter-spacing: -0.063vw;
            text-transform: uppercase;
            word-break: break-word;

            @include mobile {
              width: 80%;
              font-size: 1.1667rem;
              line-height: 128%;
              letter-spacing: -0.42px;
            }
          }

          .date-post {
            display: block;
            // font-size: 34px;
            font-size: 1.771vw;
            // letter-spacing: -1px;
            letter-spacing: -0.052vw;
            color: var(--white500);

            @include mobile {
              font-size: 1rem;
              letter-spacing: -0.36px;
            }
          }
        }
      }
    }
  }

  .link-magazine {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // padding-right: 55px;
    padding: 16.667vw 2.865vw 16.667vw 0;

    @include desktopToTablet {
      padding-right: 44px;
    }

    @include tabletToMobile {
      padding-right: 33px;
    }

    @include mobile {
      padding: 100px 20px 84px 0;
    }

    a {
      > div {
        display: flex;
        align-items: center;
        // gap: 37px;
        gap: 1.927vw;

        @include mobile {
          gap: 14px;
        }

        p {
          word-break: normal;
          white-space: nowrap;
          // font-size: 3.75rem;
          font-size: 3.125vw;
          text-transform: uppercase;

          @include mobile {
            font-size: 1.5rem;
          }
        }

        img {
          // height: 22px;
          height: 1.146vw;

          @include mobile {
            height: 8px;
          }
        }
      }
    }
  }
}

#detail-magazine {
  padding: 178px 80px 0;

  @include desktopToTablet {
    padding: 120px 50px 0;
  }

  @include tabletToMobile {
    padding: 92px 25px 0;
  }

  @include mobile {
    padding: 92px 10px 0;
  }

  .title-magazine {
    text-align: center;
    text-transform: uppercase;

    h2 {
      // margin-bottom: 150px;
      margin-bottom: 7.813vw;

      @include mobile {
        margin-bottom: 55px;
      }

      > a {
        // font-size: 6.25rem;
        font-size: 5.208vw;
        font-weight: 300;
        // margin-bottom: 150px;
        // letter-spacing: -3px;
        letter-spacing: -0.156vw;

        @include mobile {
          font-size: 2.5rem;
        }
      }
    }
  }

  .imgSlider-magazine {
    filter: blur(300px);

    .img-magazine {
      width: 100%;
      height: 48.646vw;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @include mobile {
        width: 100%;
        height: 280px;
      }

      img {
        width: auto;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  // swiper
  .swiper-pagination {
    // margin-bottom: 40px;
    margin-bottom: 1.823vw;

    @include mobile {
      margin-bottom: 5px;
    }
  }

  .swiper-pagination-bullet {
    background-color: var(--black);
    width: 11px;
    height: 11px;
    margin: 0 6px;
    opacity: 1;

    @include desktopToTablet {
      width: 8px;
      height: 8px;
      margin: 0 4px;
    }

    @include tabletToMobile {
      width: 6px;
      height: 6px;
      margin: 0 3px;
    }

    @include mobile {
      width: 3px;
      height: 3px;
      margin: 0 1.5px;
      // width: 8px;
      // height: 8px;
      // margin: 0 4px;
    }

    &.swiper-pagination-bullet-active {
      background-color: var(--blue);
    }
  }

  .textBox-detail {
    // padding: 60px 0 170px;
    padding: 3.125vw 0 8.854vw;
    transform: translateY(10%);
    opacity: 0;

    @include mobile {
      padding: 12px 0 60px;
    }

    .title-detail {
      // font-size: 3.75rem;
      font-size: 3.125vw;
      font-weight: 500;
      line-height: 116%;
      // letter-spacing: -1.2px;
      letter-spacing: -0.063vw;
      text-transform: uppercase;

      @include mobile {
        font-size: 1.333rem;
        letter-spacing: -0.32px;
      }
    }

    .date-detail {
      display: block;
      // margin: 40px 0 80px;
      margin: 2.083vw 0 0.625vw;
      // font-size: 2.125rem;
      font-size: 1.771vw;
      // letter-spacing: -1px;
      letter-spacing: -0.052vw;
      color: var(--white500);

      @include mobile {
        margin: 8px 0 18px;
        font-size: 1rem;
        letter-spacing: -0.36px;
      }
    }

    .content-detail {
      pre {
        margin: 0;
        // font-size: 2.5rem;
        font-size: 1.563vw;
        font-family: var(--font-pop);
        font-weight: 300;
        line-height: 160%;
        // letter-spacing: -0.9px;
        letter-spacing: -0.047vw;
        white-space: pre-wrap;
        word-break: break-word;

        @include mobile {
          font-size: 1rem;
          line-height: 133%;
          letter-spacing: -0.36px;
        }
      }
    }
  }

  .navigation-magazine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 55px 0 180px;
    padding: 2.865vw 0 9.375vw;
    border-top: 1px solid var(--black300);

    @include mobile {
      padding: 15px 0 80px;
    }

    .prev-magazine,
    .next-magazine {
      opacity: 0.5;
      transition: opacity 0.3s, transform 0.3s;

      a {
        display: flex;
        align-items: center;
        // gap: 30px;
        gap: 1.563vw;
        // font-size: 2.25rem;
        font-size: 1.875vw;
        font-weight: 300;
        line-height: 133%;
        letter-spacing: -1px;

        @include mobile {
          gap: 8px;
          font-size: 1rem;
          letter-spacing: -0.36px;
        }

        img {
          // height: 2.25rem;
          height: 1.875vw;

          @include mobile {
            height: 1rem;
          }
        }
      }

      &:hover {
        opacity: 1;
      }
    }

    .prev-magazine {
      img {
        transform: rotate(180deg);
      }
      &:hover {
        transform: translateX(10%);
      }
    }

    .next-magazine {
      &:hover {
        transform: translateX(-10%);
      }
    }
  }
}

#list-magazine {
  // 임시
  display: flex;
  flex-direction: column;

  padding: 178px 80px 0;

  @include desktopToTablet {
    padding: 120px 50px 0;
  }

  @include tabletToMobile {
    padding: 92px 25px 0;
  }

  @include mobile {
    padding: 92px 10px 0;
  }

  .title-magazine {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 9.375vw;

    @include mobile {
      margin-bottom: 90px;
    }

    h2 {
      // font-size: 6.25rem;
      font-size: 5.208vw;
      font-weight: 300;
      // margin-bottom: 150px;
      // letter-spacing: -3px;
      letter-spacing: -0.156vw;
      margin-bottom: 7.813vw;

      @include mobile {
        font-size: 2.5rem;
        margin: 0;
      }
    }
  }

  .slider-magazine_container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    margin-bottom: 120px;

    .sliderBox-magazine {
      overflow: hidden;

      // swiper
      .swiper-slide {
        height: 100%;
      }

      .swiper-wrapper {
        width: max-content;
        display: flex;
      }

      .slider-magazine {
        width: 100%;
        height: 100%;
        background-color: var(--black150);
        overflow: hidden;

        a {
          display: flex;
          width: 100%;
          height: 371px;

          .img-slider {
            flex: 4;

            img {
              height: 100%;
              object-fit: cover;
            }
          }

          .text-slider {
            flex: 6;
            padding: 60px;

            .date-slider {
              font-size: 34px;
              font-weight: 400;
              color: var(--white500);
            }

            .title-slider {
              width: 70%;
              h2 {
                font-size: 50px;
                font-weight: 500;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }

    .sliderBtn-magazine {
      display: flex;
      align-items: center;
      gap: 30px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 66px;
        height: 66px;

        background-color: var(--grey500);
        transition: background-color 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          img {
            opacity: 1;
          }
          background-color: var(--blue);
        }

        &.swiper-button-prev {
          img {
            transform: rotate(180deg);
          }
        }

        img {
          object-fit: cover;
          max-width: 27px;
          opacity: 0.1;
          transition: opacity 0.3s ease-in-out;
        }
      }
    }
  }

  .archive-magazine_container {
    .list-archive {
      border-top: 2px solid var(--black300);

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 28px 44px;
        border-bottom: 2px solid var(--black300);

        font-size: 36px;
        text-transform: uppercase;

        .left-post {
          display: flex;
          gap: 106px;

          span {
            font-weight: 500;
          }

          a {
              font-weight: 300;
              letter-spacing: -1px;
          }
        }

        .right-post {
          time {
            font-weight: 300;
            letter-spacing: -1px;
            color: var(--white500);
          }
        }
      }
    }
  }
}
