#googleEarth-vnsTeam {
  position: relative;
  padding-top: 178px;

  @include desktopToTablet {
    padding-top: 120px;
  }

  @include tabletToMobile {
    padding-top: 92px;
  }

  @include mobile {
    padding-top: 92px;
  }

  .googleEarth-text {
    position: absolute;
    // top: 285px;
    top: 14.844vw;
    left: 50%;
    transform: translateX(-50%);
    // font-size: 7.5rem;
    font-size: 6.25vw;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;

    @include mobile {
      position: static;
      transform: translateX(0);
      margin-bottom: 20px;
    }
  }

  .video_container {
    width: 100%;
    overflow: hidden;

    #googleEarth_video {
      width: 100%;
      pointer-events: none;
    }
  }
}

#card-vns {
  width: 100%;
  height: calc(100dvh + 200px);
  margin-bottom: 80px;

  @include mobile {
    height: 811px;
  }

  .card-vns_conatier {
    display: flex;
    align-items: center;
    justify-content: center;
    // gap: 166px;
    gap: 8.646vw;

    @include mobile {
      flex-direction: column;
      gap: 20px;
      transform: translateY(-10px);
    }

    .card-img {
      transform: translateY(-30%);

      @include mobile {
        transform: translateX(-30%);
      }

      &:nth-child(2) {
        transform: translateY(30%);

        @include mobile {
          transform: translateX(30%);
        }
      }

      &:nth-child(3) {
        @include mobile {
          transform: translateX(-15%);
        }
      }

      img {
        max-width: 422px;
        width: 21.979vw;

        @include mobile {
          width: 178px;
        }
      }
    }
  }
}

#inHouse-vns {
  width: 100%;
  height: 100dvh;

  display: flex;
  align-items: center;
  justify-content: center;

  .inHouse-vns_container {
    width: 100px;
    height: 100px;
    background-color: var(--blue);
    overflow: hidden;

    @include tabletToMobile {
      width: 50px;
      height: 50px;
    }

    @include mobile {
      width: 25px;
      height: 25px;
    }

    .inHouse-vns_inner {
      display: flex;
      justify-content: space-between;
      gap: 60px;
      width: 100%;
      height: 100%;
      padding: 50px 0 95px 50px;
      transform: scale(0.1);
      opacity: 0;

      @include desktopToTablet {
        padding: 50px 0 50px 40px;
        gap: 30px;
      }

      @include tabletToMobile {
        padding: 50px 0 25px 25px;
        gap: 20px;
      }

      @include mobile {
        align-items: flex-end;
        flex-direction: column;
        padding: 28px 0 0;
      }
    }

    .inHouse-text {
      text-transform: uppercase;

      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }

      h3 {
        // font-size: 12.5rem;
        font-size: 10.417vw;
        line-height: 105%;

        @include mobile {
          font-size: 5.83rem;
          letter-spacing: -2.1px;
        }
      }

      p {
        // font-size: 1.125rem;
        font-size: 0.937vw;

        @include tabletToMobile {
          font-size: 0.65rem;
        }

        @include mobile {
          font-size: 1rem;
          letter-spacing: -0.36px;
        }
      }
    }

    .inHouse-img {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      max-width: 858px;
      width: 44.688vw;
      height: 100%;

      @include mobile {
        width: 304px;
        overflow: hidden;
      }

      img {
        @include mobile {
          width: auto;
          height: 472px;
        }
      }
    }
  }
}

#text-color-main-01 {
  &.text-color-vns {
    margin-top: 200px;

    @include mobile {
      margin-top: 90px;
    }

    .color_container {
      .color_text {
        @include mobile {
          font-size: 4.166rem;
        }
      }
    }
  }
}

#text-vns-01 {
  padding: 95px 80px 110px;
  // font-size: 1rem;
  font-size: 0.833vw;

  @include desktopToTablet {
    padding: 45px 40px 55px;
  }

  @include tabletToMobile {
    padding: 30px 25px 45px;
    font-size: 0.6923rem;
  }

  @include mobile {
    padding: 16px 54px 100px 10px;
    font-size: 1rem;
  }
}

#teamMember-vns {
  padding: 0 80px;
  margin-bottom: 110px;

  @include desktopToTablet {
    padding: 0 40px;
  }

  @include tabletToMobile {
    padding: 0 25px;
  }

  @include mobile {
    padding: 0 55px;
  }

  .teamMember-vns_container {
    height: 562px;
    overflow: hidden;

    @include tabletToMobile {
      height: 380px;
    }

    @include mobile {
      height: 562px;
    }

    .teamMember_inner {
      // gsap 변경전
      // display: flex;
      // flex-direction: column;
      // gap: 60px;

       // @include tabletToMobile {
      //   gap: 30px;
      // }
      // @include mobile {
      //   gap: 30px;
      // }

      // gsap 변경후
      position: relative;
      height: 562px;
     
      @include tabletToMobile {
        height: 380px;
      }
  
      @include mobile {
        height: 562px;
      }

      .member {
        // gsap 변경전
        // display: flex;
        // gap: 50px;
        // height: 562px;
        // overflow: hidden;

        // @include desktopToTablet {
        //   gap: 40px;
        // }

        // @include tabletToMobile {
        //   gap: 30px;
        //   height: 380px;
        // }

        // @include mobile {
        //   flex-direction: column;
        //   gap: 26px;
        //   height: 562px;
        // }

        // gsap 변경후
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        gap: 50px;
        width: 100%;
        height: 0;
        background-color: var(--black);
        overflow: hidden;

        &.Bella {
          height: 100%;
        }

        
        @include desktopToTablet {
          gap: 40px;
        }

        @include tabletToMobile {
          gap: 30px;
        }

        @include mobile {
          flex-direction: column;
          gap: 26px;
        }


        .member-img {
          @include mobile {
            max-width: 260px;
            margin: 0 auto;
          }
        }

        .member-text {
          display: flex;
          flex-direction: column;
          gap: 80px;

          @include desktopToTablet {
            gap: 65px;
          }

          @include tabletToMobile {
            gap: 35px;
          }

          @include mobile {
            gap: 28px;
          }

          .member-info {
            font-size: 2.5rem;

            @include desktopToTablet {
              font-size: 2.2rem;
            }

            @include tabletToMobile {
              font-size: 1.8rem;
            }

            @include mobile {
              font-size: 1.66rem;
              text-align: center;
            }
          }

          .member-skills {
            ul {
              display: flex;
              flex-direction: column;
              gap: 60px;

              font-size: 1.5rem;
              color: var(--blue);

              @include desktopToTablet {
                gap: 50px;
                font-size: 1.3rem;
              }

              @include tabletToMobile {
                gap: 40px;
                font-size: 1.1rem;
              }

              @include mobile {
                gap: 30px;
                align-items: center;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
