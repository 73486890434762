#footer {
  padding: 0 50px 70px;
  background-color: var(--black);
  color: var(--white);
  overflow: hidden;

  @include desktopToTablet {
    padding: 0 44px 60px;
  }

  @include tabletToMobile {
    padding: 0 33px 50px;
  }

  @include mobile {
    padding: 0 20px 40px;
  }

  .email-footer {
    background-color: var(--black);
    color: var(--white);
    overflow: hidden;
    padding-bottom: 1rem;

    .email-text {
      margin-bottom: 50px;
      font-size: 1.375rem;
      font-weight: 200;
      text-align: center;
      letter-spacing: -0.034vw;

      @include desktopToTablet {
        font-size: 1.15rem;
      }

      @include tabletToMobile {
        font-size: 1rem;
      }

      @include mobile {
        display: none;
      }
    }

    .email-gradiant {
      display: flex;
      align-items: center;
      width: 100%;
      height: 170px;
      padding-left: 60px;
      background: linear-gradient(to top, rgba(56, 42, 208, 0.3) 0%, rgba(56, 42, 208, 0) 100%);

      @include mobile {
        height: 100px;
        padding-left: 0;
        justify-content: center;
      }

      a {
        font-size: 2.5rem;
        font-weight: 200;
        letter-spacing: -0.063vw;
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-thickness: 2px;

        @include desktopToTablet {
          font-size: 2rem;
        }

        @include tabletToMobile {
          font-size: 1.5rem;
        }

        @include mobile {
          font-size: 1.33rem;
          letter-spacing: -0.48px;
        }
      }
    }

    .email-table {
      padding-top: 2px;
      table {
        width: 100%;
        border-collapse: collapse;

        colgroup {
          col {
            width: 50%;

            @include mobile {
              width: 100%;
            }
          }
        }

        @include mobile {
          tr {
            &:first-child {
              display: flex;
              flex-direction: column;

              td {
                &:nth-child(2) {
                  height: 100px;
                }
              }
            }
          }
        }
      }

      .line {
        > span {
          background-color: var(--blue);
        }
      }

      p {
        // height: 59.81vh;
        height: 33.645vw;
        // padding-top: 9.26vh;
        padding-top: 4.68vw;

        // font-size: 1.875rem;
        font-size: 1.563vw;
        font-weight: 200;
        letter-spacing: -0.047vw;

        @include mobile {
          height: 80px;
          padding-top: 0;
          font-size: 1.16rem;
          line-height: 80px;
          text-align: center;
          letter-spacing: -0.42px;
        }
      }

      img {
        margin: 5px 0;
      }
    }
  }

  .copyright-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    font-size: 1.875rem;
    font-weight: 200;
    line-height: 1;
    letter-spacing: -0.9px;

    @include desktopToTablet {
      font-size: 1.5rem;
      letter-spacing: -0.6px;
    }

    @include tabletToMobile {
      font-size: 1.2rem;
      letter-spacing: -0.36px;
    }

    @include mobile {
      font-size: 1rem;
      letter-spacing: -0.36px;
    }
  }

  .information-footer {
    font-size: 1.25rem;
    font-weight: 300;
    color: var(--black250);
    letter-spacing: -0.66px;

    @include desktopToTablet {
      font-size: 1rem;
      letter-spacing: -0.44px;
    }

    @include tabletToMobile {
      font-size: 1rem;
      letter-spacing: -0.44px;
    }

    @include mobile {
      font-size: 1rem;
      letter-spacing: -0.36px;
    }
  }
}
