:root {
  --font-pop: "Poppins", sans-serif;

  --black: #000;
  --black50: #0F0F0F;
  --black100: #161616;
  --black150: #191919;
  --black200: #222222;
  --black250: #4E4E4E;
  --black300: #505050;
  
  --white: #fff;
  --white100: #eee;
  --white200: #d6d6d6;
  --white300: #dbdbdb;
  --white400: #a3a3a3;
  --white500: #949494;

  --grey: #E5E5E5;
  --grey500: #3d3d3d;

  --blue: #412FFF;

  // 폰트 셋팅
  font-family: var(--font-pop), sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;

  // 폰트 최적화
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  @media (max-width: 1600px) {
    font-size: 14px;
    line-height: 1.4;
  }

  @media (max-width: 760px) {
    font-size: 13px;
    line-height: 1.3;
  }

  @media (max-width: 430px) {
      font-size: 12px;
      line-height: 1.2;
  }
}