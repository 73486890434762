#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 178px;
  z-index: 100;

  @include desktopToTablet {
    height: 120px;
    font-size: 1.3rem;
  }

  @include tabletToMobile {
    height: 80px;
    font-size: 1.2rem;
  }

  @include mobile {
    height: 55px;
  }

  .header_container {
    position: relative;
    width: 100%;
    height: 100%;

    .icon-header {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 0 80px;
      z-index: 100;
      transition: background-color 1s ease-in-out;

      @include desktopToTablet {
        padding: 0 40px;
      }

      @include tabletToMobile {
        padding: 0 25px;
      }

      @include mobile {
        padding: 0 10px;

        // &.show {
        //   background-color: var(--black);
        // }
      }

      a {
        display: flex;
        align-items: center;

        img {
          max-width: 300px;

          @include desktopToTablet {
            max-width: 250px;
          }

          @include tabletToMobile {
            max-width: 239px;
          }

          @include mobile {
            max-width: 239px;
          }
        }
      }

      .menu-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: var(--blue);
        cursor: pointer;

        @include desktopToTablet {
          width: 40px;
          height: 40px;
        }

        @include tabletToMobile {
          width: 31px;
          height: 31px;
        }

        @include mobile {
          width: 31px;
          height: 31px;
        }
      }
    }
  }
}

// 메뉴
.menu-wrap {
  position: relative;
  width: 24px;
  height: 12px;
  cursor: pointer;

  @include desktopToTablet {
    width: 18px;
    height: 9px;
  }

  @include tabletToMobile {
    width: 15px;
    height: 7.5px;
  }

  @include mobile {
    width: 15px;
    height: 7.5px;
  }
}

.menu-wrap .menu-line {
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: var(--white);
  left: 0;

  @include tabletToMobile {
    height: 2px;
  }

  @include mobile {
    height: 1px;
  }
}

.menu-wrap .menu-line:first-child {
  top: 0;
  transform-origin: 50% 50%;
  transition: all 0.3s ease-in-out;
}

.menu-wrap .menu-line:last-child {
  bottom: 0;
  transform-origin: 50% 50%;
  transition: all 0.3s ease-in-out;
}

// open 애니메이션
.menu-wrap.open .menu-line:first-child {
  animation: menuOpneTopLine 0.5s ease-in-out forwards;

  @keyframes menuOpneTopLine {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: calc(50% - 1px);
      transform: rotate(0);

      @include mobile {
        top: 50%;
      }
    }
    100% {
      top: calc(50% - 1px);
      transform: rotate(45deg);

      @include mobile {
        top: 50%;
      }
    }
  }

  @include mobile {
    @keyframes menuOpneTopLine {
      0% {
        top: 0;
        transform: rotate(0);
      }
      50% {
        top: calc(50% - 0.5px);
        transform: rotate(0);
      }
      100% {
        top: calc(50% - 0.5px);
        transform: rotate(45deg);
      }
    }
  }
}

.menu-wrap.open .menu-line:last-child {
  animation: menuOpneBottomLine 0.5s ease-in-out forwards;

  @keyframes menuOpneBottomLine {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: calc(50% - 1px);
      transform: rotate(0);
    }
    100% {
      bottom: calc(50% - 1px);
      transform: rotate(-45deg);
    }
  }

  @include mobile {
    @keyframes menuOpneBottomLine {
      0% {
        bottom: 0;
        transform: rotate(0);
      }
      50% {
        bottom: calc(50% - 0.5px);
        transform: rotate(0);
      }
      100% {
        bottom: calc(50% - 0.5px);
        transform: rotate(-45deg);
      }
    }
  }
}

// close 애니메이션
.menu-wrap.close .menu-line:first-child {
  animation: menuCloseTopLine 0.5s ease-in-out forwards;

  @keyframes menuCloseTopLine {
    0% {
      top: calc(50% - 1px);
      transform: rotate(45deg);
    }
    50% {
      top: calc(50% - 1px);
      transform: rotate(0);
    }
    100% {
      top: 0;
      transform: rotate(0);
    }
  }
}

.menu-wrap.close .menu-line:last-child {
  animation: menuCloseBottomLine 0.5s ease-in-out forwards;

  @keyframes menuCloseBottomLine {
    0% {
      bottom: calc(50% - 1px);
      transform: rotate(-45deg);
    }
    50% {
      bottom: calc(50% - 1px);
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }
}

.nav-header_container {
  display: none;
  position: absolute;
  bottom: calc(-100dvh + 178px);
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--black);
  opacity: 0;
  overflow: hidden;
  z-index: 99;
  color: var(--white);

  @include desktopToTablet {
    bottom: calc(-100dvh + 120px);
  }

  @include tabletToMobile {
    bottom: calc(-100dvh + 80px);
  }

  @include mobile {
    bottom: calc(-100dvh + 55px);
    overflow-y: auto;
  }

  a {
    transition: color 0.3s ease-in-out;
    line-height: 1;
    text-transform: uppercase;

    &:hover {
      color: var(--blue);
    }
  }

  .nav-header_inner {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    gap: 60px;
    width: 100%;
    min-height: 100dvh;
    padding: 178px 0 100px 80px;

    @include desktopToTablet {
      gap: 40px;
      padding: 120px 0 80px 40px;
    }

    @include tabletToMobile {
      gap: 30px;
      padding: 80px 0 40px 25px;
    }

    @include mobile {
      gap: 20px;
      // padding: 0 0 20px 10px;
      padding: 55px 0 20px 10px;
      min-height: auto;
    }

    .img-nav {
      display: flex;
      gap: 50px;
      width: 60%;
      height: auto;

      @include desktopToTablet {
        gap: 40px;
      }

      @include tabletToMobile {
        gap: 30px;
      }

      @include mobile {
        flex-direction: column;
        gap: 10px;
        max-width: 196.5px;
      }

      > div {
        width: 50%;

        @include mobile {
          width: 100%;

          &:first-child {
            height: 311px;
          }
        }

        &.img-right-nav {
          width: calc(50% - 50px);
          display: flex;
          flex-direction: column;
          gap: 50px;

          @include mobile {
            gap: 10px;
            width: 100%;
          }

          a {
            gap: 15px;
            // padding: 0 0 30px 30px;
            padding: 0 0 1.56vw 1.56vw;
            // font-size: 2.5rem;
            font-size: 2.08vw;

            @include desktopToTablet {
              gap: 12px;
            }

            @include tabletToMobile {
              gap: 8px;
            }

            @include mobile {
              gap: 5px;
              height: 196px;
              padding: 0 0 20px 20px;
              font-size: 1.66rem;
            }
          }
        }

        a {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 100%;
          height: 100%;
          // padding: 0 0 40px 40px;
          padding: 0 0 2.08vw 2.08vw;
          transition: transform 1s ease-in-out, color 0.3s ease-in-out, opacity 1s ease-in-out;
          transform: translateX(50%);
          opacity: 0;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          // font-size: 5rem;
          font-size: 4.16vw;
          letter-spacing: -0.8px;
          word-break: break-all;

          @include mobile {
            padding: 0 0 30px 20px;
            font-size: 2.5rem;
            letter-spacing: -0.6px;
          }
        }
      }
    }

    .text-nav {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1 1 0%;
      height: 100%;
      transition: transform 1s ease-in-out, opacity 1s ease-in-out;
      transform: translateY(100%);
      opacity: 0;

      @include mobile {
        margin-top: 55px;
        justify-content: flex-start;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include mobile {
          gap: 6px;
        }

        &.sub-nav {
          display: flex;
          flex-direction: row;
          gap: 50px;
          margin-top: 36px;

          @include tabletToMobile {
            margin-top: 26px;
            gap: 25px;
          }

          @include mobile {
            flex-direction: column;
            gap: 5px;
            margin-top: 16px;
          }

          a {
            font-size: 1.375rem;
            letter-spacing: -0.44px;

            @include tabletToMobile {
              font-size: 1rem;
            }

            @include mobile {
              font-size: 1rem;
              letter-spacing: -0.24px;
            }
          }
        }
      }

      a {
        // font-size: 3.75rem;
        font-size: 3.125vw;
        font-weight: 500;
        letter-spacing: -1.2px;

        @include mobile {
          font-size: 2rem;
          letter-spacing: -0.48px;
        }
      }
    }
  }
}
