#video-main {
  position: relative;

  .video_container {
    width: 100%;
    height: 100dvh;
    overflow: hidden;

    @include mobile {
      height: 278px;
    }

    #ink_video {
      height: 150%;
      pointer-events: none;
    }
  }

  .video-text_container {
    position: absolute;
    bottom: 146px;
    left: 80px;
    display: flex;
    align-items: flex-end;
    gap: 46px;
    z-index: 1;

    @include desktopToTablet {
      bottom: 75px;
      gap: 24px;
      left: 40px;
    }

    @include tabletToMobile {
      bottom: 35px;
      gap: 16px;
      left: 25px;
    }

    @include mobile {
      top: 70%;
      bottom: 0;
      left: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;
    }

    p {
      text-transform: uppercase;

      &:first-child {
        // font-size: 7.5rem;
        font-size: 6.25vw;
        line-height: 1;
        // letter-spacing: -2.4px;
        letter-spacing: -0.125vw;

        @include mobile {
          font-size: 2.83rem;
          letter-spacing: -0.68px;
        }
      }

      &:last-child {
        // padding-bottom: 0.675rem;
        padding-bottom: 0.5625vw;
        // font-size: 1.125rem;
        font-size: 0.93vw;
        // letter-spacing: -0.54px;
        letter-spacing: -0.028vw;

        @include desktopToTablet {
          // font-size: 1rem;
        }

        @include tabletToMobile {
          // font-size: 1rem;
        }

        @include mobile {
          padding-bottom: 0;
          font-size: 1rem;
          letter-spacing: -0.36px;
        }
      }
    }
  }
}

#text-main-01 {
  display: flex;
  align-items: center;
  width: 100%;
  // margin: 135px 0 300px;
  margin: 7vw 0 15.625vw;
  padding: 0 80px;

  // font-size: 2.5rem;
  font-size: 2.083vw;
  letter-spacing: -0.063vw;
  line-height: 112.5%;
  text-transform: uppercase;

  @include desktopToTablet {
    padding: 0 40px;
  }

  @include tabletToMobile {
    padding: 0 25px;
  }

  @include mobile {
    display: none;
  }
}

#text-color-main-01 {
  position: relative;

  @include mobile {
    &.text-color-main-01 {
      margin-top: 280px;
    }

    &.text-color-main-03 {
      margin-top: 80px;
    }
  }

  &.text-color-main-02 {
    .color_container {
      padding-bottom: 32.41vh;
      background-size: 100% 200%;
      background-image: linear-gradient(to top, var(--black) 0%, var(--black) 100%);
      background-color: var(--black50);
      background-repeat: no-repeat;
      background-position-y: 169.5%;

      @include mobile {
        padding-bottom: 34px;
        background-position-y: 167.5%;
      }

      .color_text {
        // font-size: 18.75rem;
        font-size: 15.625vw;
      }
    }
  }

  .color_container {
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    // gap: 80px;
    gap: 4.17vw;

    @include desktopToTablet {
      padding: 0 40px;
    }

    @include tabletToMobile {
      padding: 0 25px;
    }

    @include mobile {
      padding: 0 10px;
    }

    .color_text {
      // font-size: 12.5rem;
      font-size: 10.42vw;
      font-weight: 300;
      line-height: 105%;
      // letter-spacing: -6px;
      letter-spacing: -0.313vw;
      text-transform: uppercase;
      background-size: 200%;
      background-image: linear-gradient(to right, #412fff 0%, #412fff 100%);
      background-color: var(--white);
      -webkit-background-clip: text;
      background-clip: text;
      background-repeat: no-repeat;
      color: transparent;
      background-position-x: 200%;

      @include mobile {
        font-size: 3.33rem;
        letter-spacing: -1.2px;
      }
    }
  }

  .color_img {
    position: absolute;
    top: 60%;
    right: 0;
    max-width: 586px;
    width: 30.52vw;
    min-width: 162px;
    z-index: 1;
  }
}

#text-main-02 {
  // margin: 140px 0 305px;
  margin: 7.552vw 0 15.85vw;
  padding: 0 45px;
  // font-size: 1.375rem;
  font-size: 1.146vw;

  @include desktopToTablet {
    padding: 0 40px;
  }

  @include tabletToMobile {
    padding: 0 25px;
    font-size: 1rem;
  }

  @include mobile {
    margin: 40px 0 90px;
    padding: 0 10px;
    font-size: 1rem;
  }
}

#how-main {
  margin-bottom: 170px;

  @include mobile {
    margin-bottom: 100px;
  }

  h3,
  h4 {
    // font-size: 9.375rem;
    font-size: 7.8125vw;
    color: var(--blue);

    @include mobile {
      font-size: 2.5rem;
    }
  }

  h3 {
    padding-left: 50px;

    @include desktopToTablet {
      padding-left: 25px;
    }

    @include tabletToMobile {
      padding-left: 15px;
    }

    @include mobile {
      padding-left: 10px;
    }
  }

  h4 {
    padding-right: 40px;
    text-align: right;

    @include desktopToTablet {
      padding-right: 25px;
    }

    @include tabletToMobile {
      padding-right: 15px;
    }

    @include mobile {
      padding-right: 10px;
    }
  }

  .how-prompt {
    position: relative;
    width: 100%;
    // overflow: hidden;

    @include mobile {
      height: 226px;
    }

    .how-img_container {
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;

      .how-img_inner {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
      }

      .how-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100vw;
          height: 100%;
          object-fit: cover;
        }
      }

      .how-img_nav {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 100%;
        background-color: var(--blue);

        @include tabletToMobile {
          width: 4px;
        }

        @include mobile {
          width: 4px;
        }

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 70px;
          height: 70px;
          background-color: var(--blue);
          cursor: grab;

          @include tabletToMobile {
            width: 35px;
            height: 35px;
          }

          @include mobile {
            width: 35px;
            height: 35px;
          }

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            width: 14px;
            height: 14px;
            background-image: url(../../images/icon/icon_arrow.svg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1;

            @include tabletToMobile {
              width: 7px;
              height: 7px;
            }

            @include mobile {
              width: 7px;
              height: 7px;
            }
          }

          &::before {
            left: 0;
            transform: translate(50%, -50%) rotate(180deg);
          }

          &::after {
            right: 0;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .propmpt {
      display: flex;
      align-items: center;
      height: 100%;
      background-color: #101315;
      // font-size: 3.75rem;
      font-size: 3.125vw;
      color: var(--blue);
      // letter-spacing: -0.36px;
      letter-spacing: -0.019vw;
    }
  }
}

#discover-main {
  width: 100%;
  // margin-bottom: 300px;
  margin-bottom: 15.625vw;
  overflow: hidden;

  @include mobile {
    margin-bottom: 100px;
  }

  .discover-img {
    width: 100%;
    overflow: hidden;

    @include mobile {
      height: 290px;
    }
  }

  .text-mask_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

    @include mobile {
      height: 220px;
    }

    .discoverText-mask {
      width: 100%;
      display: flex;
      flex-direction: column;
      // gap: 100px;
      gap: 5.208vw;

      @include mobile {
        gap: 30px;
      }

      // mask
      background: url(../../images/main_03.jpg);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      mask-image: url(../../images/main_03.jpg);
      -webkit-mask-image: url(../../images/main_03.jpg);
      mask-size: cover;
      -webkit-mask-size: cover;
      mask-repeat: no-repeat;
      background-position: bottom left 50%;

      p {
        // font-size: 12.5rem;
        font-size: 10.42vw;
        line-height: 1;
        // letter-spacing: -6px;
        letter-spacing: -0.313vw;
        text-align: center;
        text-transform: uppercase;

        &:nth-child(1),
        &:nth-child(3) {
          margin-left: -50%;
        }

        &:nth-child(2) {
          margin-right: -50%;
        }

        @include mobile {
          font-size: 3.33rem;
          letter-spacing: -1.2px;
        }
      }
    }
  }

  .discover-text {
    width: 100%;
    p {
      margin-left: 20%;
      // font-size: 1.375rem;
      font-size: 1.146vw;

      @include tabletToMobile {
        font-size: 0.6923rem;
      }

      @include mobile {
        margin-left: 10px;
        font-size: 1rem;
      }
    }
  }
}

#imgBox-main-01 {
  // margin-bottom: 230px;
  margin-bottom: 11.979vw;

  @include mobile {
    margin-bottom: 528px;
  }

  .imgBox-main-01_container {
    position: relative;
    background-size: 100% 200%;
    background-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 100%);
    background-color: var(--black50);
    background-repeat: no-repeat;
    background-position-y: -60%;
  }

  .imgBox01-text-list_container {
    position: absolute;
    top: -5.15rem;
    right: 5.5vw;
    display: flex;
    // gap: 262px;
    gap: 13.65vw;
    z-index: 1;

    @include desktopToTablet {
      top: -4rem;
    }

    @include tabletToMobile {
      top: -2rem;
    }

    @include mobile {
      top: 0;
      left: 1rem;
      display: flex;
      flex-direction: column;
      gap: 34px;
    }

    .imgBox01-text-list {
      display: flex;
      flex-direction: column;
      // gap: 168px;
      gap: 24.26vh;

      @include mobile {
        gap: 34px;
      }

      .imgBox01-text {
        font-weight: 200;

        h3 {
          margin-bottom: 15px;
          // font-size: 2.5rem;
          font-size: 2.08vw;
          // letter-spacing: -1.2px;
          letter-spacing: -0.063vw;

          @include tabletToMobile {
            margin-bottom: 8px;
          }

          @include mobile {
            font-size: 2rem;
            margin-bottom: 8px;
            letter-spacing: -0.72px;
          }
        }

        p {
          // font-size: 1.5rem;
          font-size: 1.25vw;
          letter-spacing: -0.037vw;

          @include mobile {
            font-size: 1rem;
            letter-spacing: -0.36px;
          }
        }
      }
    }
  }

  .imgBox01-img-list_container {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100vh;
    @include mobile {
      justify-content: flex-end;
      padding-top: 130px;
    }

    .imgBox01-img_inner {
      width: 46.51vw;
      height: 46.51vw;
      overflow: hidden;

      @include mobile {
        width: 230px;
        height: 230px;
      }

      .imgbox01-img {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

#text-main-03 {
  // margin-bottom: 65px;
  margin-bottom: 3.385vw;
  overflow: hidden;
  text-align: center;

  @include mobile {
    margin-bottom: 36px;
    padding: 0;
  }

  h3 {
    // font-size: 2.5rem;
    font-size: 2.083vw;
    // margin-bottom: 34px;
    margin-bottom: 1.77vw;

    // @include tabletToMobile {
    //   font-size: 2.16rem;
    // }

    @include mobile {
      font-size: 2.16rem;
      letter-spacing: -0.78px;
    }
  }

  p {
    // font-size: 1.5rem;
    font-size: 1.25vw;
    line-height: 2;

    // @include desktopToTablet {
    //   font-size: 1.3rem;
    // }

    @include tabletToMobile {
      font-size: 1rem;
    }

    @include mobile {
      font-size: 1rem;
      line-height: 1.3;
      letter-spacing: -0.36px;
    }
  }
}

#gotoVandi-main {
  position: relative;
  width: 100%;
  height: 100dvh;

  @include mobile {
    height: 240px;
  }

  .gotoVandi-img {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30vh;
    overflow: hidden;
    z-index: 1;

    @include mobile {
      height: 90px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .gotoVandi-text_container {
    // font-size: 18.75rem;
    font-size: 15.625vw;
    letter-spacing: -3px;
    font-weight: 300;
    line-height: 1;
    text-align: center;
  }
}

#imgBox-main-02 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  overflow: hidden;

  @include mobile {
    height: 210px;
  }

  .imgBox02-img_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    transform: scale(4);

    @include mobile {
      gap: 10px;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 50px;

      @include mobile {
        gap: 10px;
      }

      &.center {
        img {
          // width: 554px;
          width: 28.854vw;

          @include mobile {
            width: 119px;
          }
        }
      }

      &.left {
        transform: translateY(-13%);
      }

      &.right {
        transform: translateY(13%);
      }

      .left1,
      .right2 {
        // width: 326px;
        // height: 326px;
        width: 16.979vw;
        height: 16.979vw;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 82px;
        }
      }

      .left2,
      .right1 {
        display: flex;
        flex-direction: column;
        gap: 50px;
        // width: 398px;
        width: 20.729vw;
      }
    }
  }
}

#text-main-04 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 150px 0 210px;
  // font-size: 1.5em;
  font-size: 1.25vw;
  text-align: center;
  line-height: 187.5%;
  letter-spacing: -0.037vw;

  @include tabletToMobile {
    font-size: 1rem;
  }

  @include mobile {
    margin: 44px 0 90px;
    font-size: 1rem;
    line-height: 1.3;
    letter-spacing: -0.36px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    // margin-top: 50px;
    margin-top: 2.604vw;
    background-color: var(--blue);

    @include tabletToMobile {
      width: 40px;
      height: 40px;
    }

    @include mobile {
      margin-top: 28px;
      width: 64px;
      height: 64px;
    }

    img {
      width: 15px;
      height: 15px;

      @include tabletToMobile {
        width: 13px;
        height: 13px;
      }

      @include mobile {
        width: 20px;
        height: 20px;
      }
    }
  }
}

#embark-main {
  .embark-img {
    max-height: 100dvh;
    overflow: hidden;

    @include mobile {
      max-height: 195px;
    }
  }

  .text-mask_container {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100vh;
    padding: 55px 0 55px;
    background-color: var(--black200);

    @include mobile {
      height: 200px;
    }

    .embarkText-mask {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 80px;

      @include desktopToTablet {
        gap: 60px;
      }

      @include tabletToMobile {
        gap: 50px;
      }

      @include mobile {
        gap: 30px;
      }

      // mask
      background: url(../../images/main_05.jpg);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      mask-image: url(../../images/main_05.jpg);
      -webkit-mask-image: url(../../images/main_05.jpg);
      mask-size: cover;
      -webkit-mask-size: cover;
      mask-repeat: no-repeat;
      background-position: bottom right;

      p {
        // font-size: 12.5rem;
        font-size: 10.42vw;
        line-height: 1;
        // letter-spacing: -6px;
        letter-spacing: -0.313vw;
        text-align: center;
        text-transform: uppercase;

        &:nth-child(1),
        &:nth-child(3) {
          margin-right: -50%;
        }

        &:nth-child(2) {
          margin-left: -50%;
        }

        @include mobile {
          font-size: 3.33rem;
          letter-spacing: -1.2px;
        }
      }
    }
  }

  .embark-text {
    width: 100%;
    padding: 80px;
    // font-size: 22px;
    font-size: 1.146vw;

    @include desktopToTablet {
      padding: 40px;
    }

    @include tabletToMobile {
      padding: 25px;
      font-size: 0.6923rem;
    }

    @include mobile {
      padding: 10px;
      font-size: 1rem;
    }
  }
}

#text-main-05 {
  display: flex;
  flex-direction: column;
  // gap: 240px;
  gap: 12.5vw;
  margin: 150px 0 380px;
  margin: 7.813vw 0 19.792vw;
  padding: 0 80px;
  margin: 7.812vw 0 19.792vw;

  @include desktopToTablet {
    padding: 0 40px;
  }

  @include tabletToMobile {
    padding: 0 25px;
  }

  @include mobile {
    gap: 34px;
    margin: 40px 0 60px;
    padding: 0 0 0 10px;
  }

  .text05-list-05 {
    display: flex;
    // gap: 200px;
    gap: 10.42vw;

    @include mobile {
      flex-direction: column;
      gap: 34px;
    }

    &.top {
      justify-content: flex-end;
    }

    .text05-text {
      font-weight: 200;

      h3 {
        // margin-bottom: 15px;
        margin-bottom: 0.781vw;
        // font-size: 2.5rem;
        font-size: 2.08vw;
        line-height: 1;
        letter-spacing: -0.063vw;

        @include mobile {
          margin-bottom: 8px;
          font-size: 2rem;
          letter-spacing: -0.72px;
        }
      }

      p {
        // font-size: 1.5rem;
        font-size: 1.25vw;
        letter-spacing: -0.037vw;

        @include mobile {
          font-size: 1rem;
          letter-spacing: -0.36px;
        }
      }
    }
  }
}

#moveLogo-main {
  // margin-bottom: 220px;
  margin-bottom: 11.458vw;

  @include mobile {
    margin-bottom: 34px;
  }

  #movelogo-slider {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 200px;

    @include desktopToTablet {
      height: 111.8px;
    }

    @include tabletToMobile {
      height: 70.61px;
    }

    @include mobile {
      height: 52.94px;
    }

    .image-box {
      width: 5852px;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      animation: bannermove 10s linear infinite;

      @include desktopToTablet {
        width: 3840px;
      }

      @include tabletToMobile {
        width: 2440px;
      }

      @include mobile {
        width: 1840px;
      }

      img {
        width: 2906px;
        margin-right: 20px;

        @include desktopToTablet {
          width: 1900px;
        }

        @include tabletToMobile {
          width: 1200px;
        }

        @include mobile {
          width: 900px;
        }
      }
    }
  }

  @keyframes bannermove {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-50%, 0);
    }
  }
}

#youCanTrust-main {
  width: 100%;
  // padding: 230px 0 610px;
  padding: 11.979vw 0 31.77vw;
  text-transform: uppercase;
  background-image: url(../../images/imgBox3/box3_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  h3 {
    // margin-bottom: 360px;
    margin-bottom: 18.75vw;
    // font-size: 6.25rem;
    font-size: 5.2vw;
    font-weight: 200;
    text-align: center;
    letter-spacing: -0.156vw;

    @include mobile {
      margin-bottom: 62px;
      font-size: 2.5rem;
      letter-spacing: -0.9px;
    }
  }

  .youCanTrust-img_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // font-size: 1.5rem;
    font-size: 1.25vw;
    // letter-spacing: -0.72px;
    letter-spacing: -0.037vw;

    @include mobile {
      flex-direction: column;
      justify-content: center;
      gap: 50px;
      padding: 0 20px;
      font-size: 1rem;
      letter-spacing: -0.36px;
    }

    > div {
      max-width: 803px;
      width: 41.82vw;

      @include mobile {
        max-width: 100%;
        width: 100%;
      }
    }

    .youCanTrust-center {
      > p {
        margin-top: 40px;
        @include mobile {
          margin-top: 10px;
        }
      }
    }

    .youCanTrust-left {
      transform: translateY(-90%);
      transform: translateX(-50%);

      @include mobile {
        transform: translateX(0);
      }

      > p {
        bottom: 20px;
        right: 30px;
        transform: rotate(180deg);

        @include mobile {
          bottom: 0;
          right: 0;
          margin-top: 10px;
          transform: rotate(0);
        }
      }
    }

    .youCanTrust-right {
      transform: translateY(90%);
      transform: translateX(50%);

      @include mobile {
        transform: translateX(0);
      }

      > p {
        top: 20px;
        left: 30px;
        @include mobile {
          top: 0;
          left: 0;
        }
      }
    }

    .youCanTrust-left,
    .youCanTrust-right {
      position: relative;

      > p {
        position: absolute;
        writing-mode: vertical-rl;

        @include mobile {
          position: static;
          writing-mode: horizontal-tb;
        }
      }
    }
  }
}

#submit-main {
  // margin-bottom: 200px;
  margin-bottom: 10.417vw;
  padding: 0 50px;

  @include desktopToTablet {
    padding: 0 44px;
  }

  @include tabletToMobile {
    padding: 0 33px;
  }

  @include mobile {
    margin-bottom: 30px;
    padding: 0 22px;
  }

  form {
    width: 100%;

    table {
      width: 100%;
      border-collapse: collapse;

      colgroup {
        col {
          width: 50%;

          @include mobile {
            width: 100%;
          }
        }
      }

      tr {
        @include mobile {
          display: flex;
          flex-direction: column;
        }

        td {
          padding: 20px 0;
          &:nth-child(2) {
            padding-left: 20px;

            @include mobile {
              padding-left: 0;
            }
          }

          @include mobile {
            padding: 10px 0;
          }
        }
      }

      p {
        padding-bottom: 9.115vw;
        // font-size: 2.5rem;
        font-size: 2.08vw;
        line-height: 125%;
        // padding-bottom: 175px;

        @include mobile {
          padding-bottom: 0;
          font-size: 1.33rem;
          letter-spacing: -0.48px;
        }
      }

      .input-container {
        display: flex;
        flex-direction: column;

        label {
          font-size: 1.5rem;
          font-weight: 300;
          color: var(--white);
          letter-spacing: -0.72px;

          @include tabletToMobile {
            font-size: 1rem;
            letter-spacing: -0.36px;
          }

          @include mobile {
            font-size: 1rem;
            letter-spacing: -0.36px;
          }

          // error
          .error {
            color: var(--blue);
            margin-left: 0.75rem;
          }
        }

        input,
        textarea {
          width: 100%;
          background-color: transparent;

          font-size: 1.5rem;
          font-weight: 300;
          color: var(--white);
          letter-spacing: -0.037px;

          @include tabletToMobile {
            font-size: 1.16rem;
            letter-spacing: -0.42px;
          }

          @include mobile {
            font-size: 1.16rem;
            letter-spacing: -0.42px;
          }

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: #505050;
          }
        }

        input {
          height: 140px;

          @include desktopToTablet {
            height: 90px;
          }

          @include tabletToMobile {
            height: 60px;
          }

          @include mobile {
            height: 45px;
          }
        }

        textarea {
          min-height: 140px;
          height: auto;
          resize: none;
          overflow: hidden;

          @include desktopToTablet {
            min-height: 90px;
          }

          @include tabletToMobile {
            min-height: 60px;
          }

          @include mobile {
            min-height: 45px;
          }
        }
      }
    }
  }

  #submitBtn {
    width: 100%;
    height: 125px;
    margin-top: 50px;
    transition: background-position 1.5s cubic-bezier(0.59, 0.18, 1, -0.11);
    background-size: 200%;
    background-image: linear-gradient(to right, #412fff 0%, #412fff 100%);
    background-color: var(--black100);
    background-repeat: no-repeat;
    background-position-x: 200%;
    cursor: pointer;

    font-size: 2.5rem;
    color: var(--white);
    text-align: center;
    letter-spacing: -0.063vw;

    @include desktopToTablet {
      height: 100px;
      font-size: 2rem;
    }

    @include tabletToMobile {
      height: 70px;
      font-size: 1.5rem;
    }

    @include mobile {
      height: 50px;
      margin-top: 20px;
      font-size: 1.33rem;
      letter-spacing: -0.48px;
    }

    &:disabled {
      // animation: submitAnimation 3s ease-in-out forwards;
      background-position-x: 100%;
      cursor: wait;

      // @keyframes submitAnimation {
      //   0% {
      //     background-position-x: 200%;
      //   }
      //   50% {
      //     background-position-x: 175%;
      //   }
      //   100% {
      //     background-position-x: 100%;
      //   }
      // }
    }
  }
}
