// 모바일
$mobile-max: 430px;

// 태블릿
$tablet-max: 760px;

// 데스크탑
$desktop-min: 1600px;

// 320, 480, 760, 960, 1200, 1600

// 모바일 미디어 쿼리
@mixin mobile {
  @media (max-width: $mobile-max) {
    @content;
  }
}

// 태블릿부터 모바일까지 미디어 쿼리
@mixin tabletToMobile {
  @media (min-width: #{$mobile-max + 1}) and (max-width: #{($tablet-max - 1)}) {
    @content;
  }
}

// 데스크탑부터 태블릿까지 미디어 쿼리
@mixin desktopToTablet {
  @media (min-width: #{$tablet-max}) and (max-width: #{($desktop-min - 1)}) {
    @content;
  }
}

// 데스크탑 미디어 쿼리
@mixin desktop {
  @media (min-width: $desktop-min) {
    @content;
  }
}