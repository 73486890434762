#content-philosophy {
  position: relative;
  width: 100%;
  padding: 27.8vw 0 21.354vw;

  @include desktopToTablet {
    padding: 26.5vw 0 21.354vw;
  }

  @include tabletToMobile {
    padding: 13.8vw 0 35.354vw;
  }

  @include mobile {
    padding: 60px 0 90px;
  }

  .philosophy-text {
    position: absolute;
    top: 178px;
    left: 0;
    width: 100%;
    z-index: 1;

    @include desktopToTablet {
      top: 120px;
    }

    @include tabletToMobile {
      top: 92px;
    }

    @include mobile {
      top: 92px;
    }

    p {
      // font-size: 3.75rem;
      font-size: 3.125vw;
      line-height: 133%;
      letter-spacing: -1.2px;
      text-align: center;
      text-transform: uppercase;

      @include desktopToTablet {
        font-size: 2.6rem;
      }

      @include tabletToMobile {
        font-size: 2.3rem;
      }

      @include mobile {
        font-size: 2rem;
        line-height: 144%;
        letter-spacing: -0.48px;
      }
    }
  }

  .philosophy-svg {
    svg {
      width: 100%;
      min-height: 760px;
      transform: scale(1.1);
    }

    path {
      stroke-dasharray: 50000;
      stroke-dashoffset: 0;
      animation: draw 7s cubic-bezier(0, 0, 1, 0.05) forwards;
    }

    @keyframes draw {
      0% {
        stroke-dashoffset: 50000;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
  }
}
